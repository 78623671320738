<template>
  <div>
    New Challenge
    <hr />
    <b-form-group label="Select Week:">
      <b-form-select v-model="week" :options="weeks" size="sm"></b-form-select>
    </b-form-group>
    <hr />

    <b-form-group v-if="week" label="Home Conference:">
      <b-form-select v-if="conferences" v-model="homeConference" :options="conferences" size="sm"></b-form-select>
    </b-form-group>

    <b-form-group v-if="homeTeams" label="Home Team:">
      <b-form-select v-model="home" :options="homeTeams" size="sm"></b-form-select>
    </b-form-group>

    <hr />

    <b-form-group v-if="home" label="Away Conference:">
      <b-form-select v-if="conferences" v-model="awayConference" :options="conferences" size="sm"></b-form-select>
    </b-form-group>

    <b-form-group v-if="awayTeams" label="Away Team:">
      <b-form-select v-model="away" :options="awayTeams" size="sm"></b-form-select>
    </b-form-group>

    <b-form-group v-if="away" label="Game Time: (2019-03-19)">
      <b-form-input v-model="event_date" default="2024-10-" />
    </b-form-group>
    <b-form-group v-if="away" label="Game Time: (12:00:00)">
      <b-form-input v-model="event_time" />
    </b-form-group>

    <hr />

    <div v-if="week && home && away">
      <h3>Vegas Odds</h3>
      <b-form-group label="Line:">
        <b-form-select v-model="linevalue" :options="line" size="sm"></b-form-select>
      </b-form-group>
      <b-form-group label="Money Line Winner">
        <b-form-input v-model="mlw" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group label="Money Line Loser">
        <b-form-input v-model="mll" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group label="Spread:">
        <b-form-input v-model="speadvalue" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group label="Over">
        <b-form-input v-model="spo" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group label="Under">
        <b-form-input v-model="spu" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group label="Points:">
        <b-form-input v-model="overunder" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group label="Over">
        <b-form-input v-model="ouo" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group label="Under">
        <b-form-input v-model="ouu" size="sm"></b-form-input>
      </b-form-group>
      <b-button variant="danger" style="margin-top:10px" class="btn-block" @click="create">Create</b-button>
      <p>{{message}}</p>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";

export default {
  name: "CreateChallenge",
  data() {
    return {
      week: null,
      weeks: [{ value: null, text: "Please Select a Week" }],
      conferences: null,

      homeConference: null,
      homeTeams: null,
      home: "",

      awayConference: null,
      awayTeams: null,
      away: "",

      event_date: "",
      event_time: "",

      linevalue: null,
      mlw: null,
      mll: null,
      speadvalue: null,
      spo: null,
      spu: null,
      overunder: null,
      ouo: null,
      ouu: null,

      message: ""
    };
  },
  computed: {
    line() {
      return [
        { value: null, text: "Please Select the Line" },
        {
          value: this.home,
          text: this.homeTeams.find(x => x.value === this.home).text
        },
        {
          value: this.away,
          text: this.awayTeams.find(x => x.value === this.away).text
        }
      ];
    }
  },
  watch: {
    homeConference() {
      let self = this;
      api(
        `query { conference(id:${this.homeConference}){ id name team { id name } } }`
      ).then(data => {
        var teams = [{ value: null, text: "Please Select Team" }];
        data.conference.team.forEach(element => {
          teams.push({
            value: element.id,
            text: element.name
          });
        });
        self.homeTeams = teams;
      });
    },
    awayConference() {
      let self = this;
      api(
        `query { conference(id:${this.awayConference}){ id name team { id name } } }`
      ).then(data => {
        var teams = [{ value: null, text: "Please Select Team" }];
        data.conference.team.forEach(element => {
          teams.push({
            value: element.id,
            text: element.name
          });
        });
        self.awayTeams = teams;
      });
    }
  },
  created() {
    let self = this;
    api(`query { weeks { id name } conferences { id name } }`).then(data => {
      data.weeks.forEach(element => {
        self.weeks.push({
          value: element.id,
          text: element.name
        });
      });
      var conferences = [{ value: null, text: "Please Select Conference" }];
      data.conferences.forEach(element => {
        conferences.push({
          value: element.id,
          text: element.name
        });
        self.conferences = conferences;
      });
    });
  },
  methods: {
    create() {
      let self = this;
      api(
        `mutation { makechallenge(week:${this.week}, home:${this.home}, away:${this.away}, date:"${this.event_date}", time:"${this.event_time}", line:${this.linevalue}, moneyLineWinner:${this.mlw}, moneyLineLoser:${this.mll}, sprd:${this.speadvalue}, spreadOver:${this.spo}, spreadUnder:${this.spu}, pnts:${this.overunder}, pointsOver:${this.ouo}, pointsUnder:${this.ouu}){ code message } }`
      ).then(data => {
        this.$router.push({ name: "Challenges" });
      });
    }
  }
};
</script>